<template>
    <div class="CommentWhat">
        <div class="CommentWhat--Comment">
            <div class="CommentWhat--CommentLogo">
                <img
                    src="../assets/images/blog_what/user.png"
                    v-if="!comment.testerImage"
                />
                <img :src="comment.testerImage" v-if="comment.testerImage" />
                <div class="CommentWhat--LevelPoints" alt="WATios">
                    <div class="CommentWhat--LevelPoints-info">
                        <i class="icon-wat_new_watio_circle"></i>
                        <p>{{ comment.testerLevelPoints }}</p>
                    </div>
                </div>
            </div>
            <div class="CommentWhat--CommentData">
                <p>{{ comment.testerNick }}</p>
                <p>{{ getCreationDate(comment.creationDate) }}</p>
                <p v-html="comment.comment"></p>
                <div class="CommentWhat--CommentActions">
                    <div>
                        <i
                            class="fa fa-thumbs-up"
                            :class="{ active: comment.ratedPositive }"
                            v-on:click="$emit('rateCommentPositive')"
                        ></i>
                        <span>{{ comment.positives }}</span>
                    </div>
                    <div>
                        <i
                            class="fa fa-thumbs-down"
                            :class="{ active: comment.ratedNegative }"
                            v-on:click="$emit('rateCommentNegative')"
                        ></i>
                        <span>{{ comment.negatives }}</span>
                    </div>
                    <div
                        class="CommentWhat--ResponseButton"
                        v-show="!noReply"
                        v-on:click="showCommentInput = !showCommentInput"
                    >
                        {{$t('tester_what_answer')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="CommentWhat--Input" v-show="showCommentInput">
            <CommentInputWhat
                v-on:createWhatComment="createWhatComment($event)"
                :placeholder="$t('tester_what_answer_to') + comment.testerNick"
            />
        </div>
        <div class="CommentWhat--Answers" v-if="answers.length > 0">
            <CommentWhat
                class="CommentWhat--Answer"
                v-for="answer in answers"
                v-bind:key="answer"
                :comment="answer"
                v-on:rateCommentPositive="
                    $emit('rateCommentPositive', answer.identifier)
                "
                v-on:rateCommentNegative="
                    $emit('rateCommentNegative', answer.identifier)
                "
                noReply
            />
        </div>
    </div>
</template>
<script>
import { defineComponent, onBeforeMount, ref } from 'vue'
import CommentInputWhat from '@/components/CommentInputWhat.vue'
import CommentWhat from '@/components/CommentWhat.vue'
import moment from 'moment'

export default defineComponent({
    emits: ['createWhatComment', 'rateCommentPositive', 'rateCommentNegative'],
    props: {
        comment: {
            type: Object,
            default: null
        },
        noReply: {
            type: Boolean,
            default: false
        },
    },
    components: {
        CommentInputWhat,
        CommentWhat,
    },
    setup(props, { emit }) {
        const showCommentInput = ref(false)

        const answers = ref([])

        onBeforeMount(()=>{            
            if (props.comment.answers) {
                answers.value = props.comment.answers.sort((a,b)=>a.identifier-b.identifier)
            }
        })

        const createWhatComment = (comment) => {
            
            emit('createWhatComment', comment)
            showCommentInput.value = false
        }
        const getCreationDate = (date) => {
            if (date == undefined) {
                return '-'
            }
            return moment(date).format('DD:MM:YYYY, HH:mm')
        }

        return {
            showCommentInput,
            createWhatComment,
            getCreationDate,
            answers
        }
    },
})
</script>
<style lang="scss" scoped>
.CommentWhat {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    &--Answers {
        margin-left: 35px;
        border-left: 2px solid #f3f3f3;
        margin-top: 20px;
        padding-left: 20px;
        @media screen and (max-width: 770px) {
            margin-left: 0px;
        }
    }
    &--Answer {
        margin-bottom: 15px;
    }
    &--Comment {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 0px;
        @media screen and (max-width: 1000px) {
            grid-template-columns: auto;
            grid-template-rows: auto auto;
            position: relative;
        }
    }
    &--CommentLogo {
        flex-shrink: 0;
        flex-basis: 40px;
        width: fit-content;
        img {
            width: 70px;
            height: 70px;
            margin-left: 0px;
            margin-bottom: 5px;
            margin-top: 5px;
            //border-radius: 50%;
            //border: 1px solid #FF1564;
        }
        @media screen and (max-width: 1000px) {
            display: flex;
            flex-direction: column;
        }
    }
    &--LevelPoints {
        display: flex;
        flex-direction: column;
        width: fit-content;
        @media screen and (max-width: 1000px) {
            height: fit-content;
            margin: 0px 0px;
        }
        &-info {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 5px;
            background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
            color: white;
            padding: 5px;
            height: 45px;
            opacity: 1;
            @media screen and (max-width: 1000px) {
                height: 100%;
            }
            i {
                font-size: 18px;
                margin: 0px;
                padding-bottom: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                    margin: 0px;
                }
            }
            p {
                font-weight: bold;
                font-size: 14px;
                margin: 0px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    &--CommentData {
        width: 99%;
        overflow-wrap: break-word;
        background: white;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 10px 40px 10px 15px;
        //border-radius: 6px;
        p {
            margin: 0;
        }
        p:nth-child(1) {
            font-size: 14px;
            font-family: 'Open Sans', Helvetica, arial, sans-serif;
            font-weight: bold;
            color: rgb(62, 62, 62);
        }
        p:nth-child(2) {
            font-size: 10px;
            font-family: 'Open Sans', Helvetica, arial, sans-serif;
            color: gray;
        }
        p:nth-child(3) {
            margin: 10px 0;
            word-wrap: break-word;
            font-size: 14px;
            font-family: 'Open Sans', Helvetica, arial, sans-serif;
            color: #404040;
        }
    }
    &--CommentActions {
        display: flex;
        font-size: 14px;
        color: #aaa;
        i {
            transition: color 0.2s linear;
            margin-right: 5px;
            cursor: pointer;
            &.active,
            &:hover {
                color: #ff1564;
            }
        }
        div {
            margin-right: 10px;
        }
    }
    &--ResponseButton {
        background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        font-weight: 500;
    }
    &--Input {
        width: calc(100% - 71px);
        margin-left: 65px;
        margin-top: 20px;
    }
}
</style>

